import React, { useEffect, useState } from "react";
import { Card, Row, Col, Table, Button, Modal, Tooltip, Drawer } from "antd";
import { MdOutlineAddShoppingCart, MdShoppingCart } from "react-icons/md";
import {
  useCreateCustomerCartMutation,
  useCreateCustomerorderMutation,
  useGetProductdisplayQuery,
} from "../productsServices";
import productsimg from "../../../assets/images/defaultimg.png";
import OrderPlace from "../modal/OrderPlace";
import AddtoCard from "../modal/AddtoCard";

const UserProduct = () => {
  const [tabledata, setTabledata] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [rowData, setRowData] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const { data: getdisplayproduct, refetch } = useGetProductdisplayQuery();
  const [saveuserproduct, { isSuccess: orderSuccess }] =
    useCreateCustomerorderMutation();
  const [savetocard] = useCreateCustomerCartMutation();
  useEffect(() => {
    if (getdisplayproduct) {
      const updatedTableData = getdisplayproduct.data.map((item) => ({
        mainco: item.mainco,
        pcategory: item.pcategory,
        pdescr: item.pdescr,
        pid: item.pid,
        pname: item.pname,
        pnewprice: item.pnewprice,
        pprice: item.pprice,
        ppic: item.ppic,
        status: item.status,
        quantity: 1,
      }));
      setTabledata(updatedTableData);
    }
  }, [getdisplayproduct]);
  const handleRefetch = () => {
    refetch();
  };
  const columns = [
    {
      title: "Products",
      render: (data) => {
        let disper = null;
        let actualprice = null;
        if (data?.pprice > data?.pnewprice) {
          actualprice = data?.pprice;
          disper = ((data?.pprice - data?.pnewprice) / data?.pprice) * 100;
        }
        return (
          <Card className="grid justify-center items-start gap-1">
            <div className="pb-1">
              <img
                src={data?.ppic ? data?.ppic : productsimg}
                alt="product"
                className="w-[10rem] h-[10rem] object-cover rounded-md"
              />
            </div>
            <div className="grid sm:grid-cols-2 grid-cols-2 gap-2">
              {/**/}
              <div className="pr-2">
                <div className=" text-left font-bold">{data?.pname}</div>
                <div className=" text-left font-bold">
                  <div className=" ">
                    <h1 className="text-[#33a69b]">
                      PKR&nbsp;{data?.pnewprice}
                    </h1>
                  </div>
                  <s>
                    {actualprice !== null && (
                      <div className="text-sm"> PKR&nbsp;{actualprice}</div>
                    )}
                  </s>
                </div>
                <div className=" text-left font-bold"></div>
              </div>
              <div className=" flex-row justify-end  gap-1">
                <div className=" flex justify-end items-start">
                  {disper !== null && (
                    <h1 className=" text-danger font-bold">
                      {disper.toFixed(1)}% off
                    </h1>
                  )}
                </div>
                <div className=" flex gap-2 pt-2 justify-end">
                  <Tooltip title="Shop Now">
                    <Button
                      type="primary"
                      className="btncustomize flex justify-center items-center"
                      icon={<MdShoppingCart className=" text-2xl" />}
                      onClick={() => {
                        setRowData(data);
                        setShowDrawer(true);
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="Add To Card">
                    <Button
                      type="primary"
                      className="btncustomize flex justify-center items-center"
                      icon={<MdOutlineAddShoppingCart className=" text-2xl" />}
                      onClick={() => {
                        setRowData(data);
                        setShowAddModal(true);
                      }}
                    />
                  </Tooltip>
                </div>
              </div>
            </div>
          </Card>
        );
      },
    },
  ];
  return (
    <div>
      <Row gutter={[9, 9]}>
        {tabledata.map((product) => (
          <Col className="col-span-24 sm:col-span-4" key={product.pid}>
            <Card>
              <Table
                columns={columns}
                dataSource={[product]}
                pagination={false}
                rowKey="pid"
                scroll={{ x: "fit-content" }}
              />
            </Card>
          </Col>
        ))}
      </Row>
      <Drawer
        title="Purchase Product"
        placement="right"
        closable={true}
        onClose={() => setShowDrawer(false)}
        open={showDrawer}
        width={400}
        className="transition-transform duration-300 ease-in-out overflow-hidden"
        destroyOnClose
      >
        <OrderPlace
          saveuserproduct={saveuserproduct}
          rowData={rowData}
          orderSuccess={orderSuccess}
          handleRefetch={handleRefetch}
          handlecancel={() => setShowDrawer(false)}
        />
      </Drawer>
      <Drawer
        title="Add To Card "
        placement="right"
        closable={true}
        open={showAddModal}
        onClose={() => setShowAddModal(false)}
        width={500}
        destroyOnClose
        footer={[
          <Button
            key="1"
            htmlType="submit"
            form="addtocard"
            // loading={addLoading}
            type="primary"
          >
            Add To Card
          </Button>,
        ]}
      >
        <AddtoCard savetocard={savetocard} rowData={rowData} />
      </Drawer>
    </div>
  );
};

export default UserProduct;
