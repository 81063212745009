// import { useLocation, Navigate, Outlet } from "react-router-dom";
// import { useSelector } from "react-redux";
// // import Header from "../layouts/Header";
// import Navbar from "../layouts/Navbar"; // Update this line

// const PrivateRoutes = () => {
//   const loginStatus = useSelector((state) => state.auth.isLoggedIn);
//   const location = useLocation();

//   return loginStatus ? (
//     <>
//       <Navbar />
//       <div className="ml-0 lg:ml-0 duration-300">
//         {/* <Header /> */}
//         <div className="flex gap-1 bg-[#d8e5f9] p-[8px] min-h-[calc(100vh-7.5rem)] rounded">
//           <main className="bg-[#ffffff] rounded shadow-lg p-2 w-full">
//             <Outlet />
//           </main>
//         </div>
//       </div>
//     </>
//   ) : (
//     <Navigate to="/login" state={{ from: location }} replace />
//   );
// };

// export default PrivateRoutes;
import { useState } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import Footer from "../layouts/Footer";

const PrivateRoutes = () => {
  const [collapsed, setCollapsed] = useState(window.innerWidth > 768);
  const [hidden, setHidden] = useState(window.innerWidth < 769);
  const loginStatus = useSelector((state) => state.auth.isLoggedIn);
  const location = useLocation();
  return loginStatus ? (
    <>
      <Sidebar
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        hidden={hidden}
      />

      <div
        className={`${
          !collapsed
            ? hidden
              ? "ml-[14.5rem]"
              : "ml-[3.125rem]"
            : hidden
            ? "ml-[13rem]"
            : "ml-0 lg:ml-[13rem]"
        } ${hidden ? "ml-0" : ""} duration-300`}
      >
        <Header setHidden={setHidden} />
        <div className="flex gap-1 bg-[#d8e5f9] p-[8px] min-h-[calc(100vh-7.5rem)] rounded ">
          <main className="bg-[#ffffff] rounded shadow-lg p-2 w-full">
            <Outlet />
          </main>
        </div>
      </div>
      <Footer />
    </>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default PrivateRoutes;
