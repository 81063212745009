import { Form, Input } from "antd";
import React from "react";
import formValidationMessages from "../../../utils/formValidationMessages";

const UpdateInvStock = ({ rowData, updatestock, updatestockData }) => {
  const [form] = Form.useForm();
  const onFinish = (values) =>
    updatestock({
      ...values,
      itemid: rowData?.itemid,

      message: "B",
    });
  return (
    <div>
      <Form
        layout="vertical"
        onFinish={onFinish}
        id="updateInvStockForm"
        form={form}
        initialValues={{
          itemid: rowData?.itemid,
          itemname: rowData?.itemname,
          currentquantity: rowData?.currentquantity,
          purchaseprice: rowData?.purchaseprice,
          currentprice: rowData?.currentprice,
        }}
        validateMessages={formValidationMessages}
        className="grid grid-cols-3 gap-2"
      >
        <Form.Item
          name="itemname"
          label="Item Name"
          rules={[{ required: true }]}
        >
          <Input placeholder="Enter Item Name" disabled />
        </Form.Item>

        <Form.Item
          name="currentquantity"
          label="Current Quantity"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input disabled placeholder="Enter Current Quantity" />
        </Form.Item>
        <Form.Item
          name="purchaseprice"
          label="Purchase Price"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Enter Purchase Price" />
        </Form.Item>
        <Form.Item
          name="currentprice"
          label="Sale Price"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Enter Sale Price" />
        </Form.Item>
      </Form>
    </div>
  );
};

export default UpdateInvStock;
