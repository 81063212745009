import Historypos from "../features/inventorypurchase/modal/Historypos";
import InvPurchaseDetail from "../features/inventorypurchase/pages/InvPurchaseDetail";
import InvStock from "../features/inventorypurchase/pages/InvStock";
import ItemRegistration from "../features/inventorypurchase/pages/ItemRegistration";
import { PointofSale } from "../features/inventorypurchase/pages/PointofSale";
import { PosReturn } from "../features/inventorypurchase/pages/PosReturn";
import PurchaseOrder from "../features/inventorypurchase/pages/PurchaseOrder";
import PurchaseReceivingFinal from "../features/inventorypurchase/pages/PurchaseReceivingFinal";
import Quotation from "../features/inventorypurchase/pages/Quotation";

export const inventoryPurchaseRoutes = [
  {
    path: "purchaseorders",
    element: <PurchaseOrder />,
  },

  {
    path: "InventoryStock",
    element: <InvStock />,
  },

  {
    path: "PODetail",
    element: <InvPurchaseDetail />,
  },
  {
    path: "purchasereceiving",
    element: <PurchaseReceivingFinal />,
  },
  {
    path: "quotation",
    element: <Quotation />,
  },
  {
    path: "pointofsale",
    element: <PointofSale />,
  },
  {
    path: "historypos",
    element: <Historypos />,
  },
  {
    path: "posreturn",
    element: <PosReturn />,
  },
  {
    path: "itemregistration",
    element: <ItemRegistration />,
  },
];
