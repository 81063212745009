import { Button, Input, message, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useLovItemListForQutationQuery } from "../inventorypurchaseService";
import useTableFilters from "../../../hooks/useTableFilter";

export const AddDetailQuotation = ({ createQuotationdetail, rowData }) => {
  const [tableData, setTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const { data: getlitemdata } = useLovItemListForQutationQuery();
  const filter = useTableFilters();
  useEffect(() => {
    if (getlitemdata) {
      const updatedTableData = getlitemdata?.data.map((item) => ({
        itemid: item.itemid,
        item: item.item,
        item_name: item.item_name,
        price: item.price,
        negotiateable: "Y",
        validdays: 7,
      }));
      setTableData(updatedTableData);
    }
  }, [getlitemdata]);
  const handleInputChange = (key, dataIndex, value) => {
    // Update the data array with the new value
    const newData = [...tableData];
    const index = newData.findIndex((item) => key === item.itemid);
    if (index > -1) {
      newData[index][dataIndex] = value;
      setTableData(newData);
    }
  };
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
  };
  const columns = [
    {
      title: "Itemid",
      dataIndex: "itemid",
    },
    {
      title: "Itemname",
      dataIndex: "item",
      ...filter("item"),
      sorter: (a, b) => a?.item?.localeCompare(b?.item),
    },
    {
      title: "Description",
      dataIndex: "item_name",
      ...filter("item_name"),
      sorter: (a, b) => a?.item_name?.localeCompare(b?.item_name),
    },
    {
      title: "Price",
      dataIndex: "price",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) => (
        <Input
          placeholder="Enter Quantity"
          value={record.quantity}
          onChange={(e) =>
            handleInputChange(record.itemid, "quantity", e.target.value)
          }
          className="border rounded px-2 py-1"
        />
      ),
    },
    // {
    //   title: "Quotedrate",
    //   dataIndex: "quotedrate",
    //   key: "quotedrate",
    //   render: (text, record) => (
    //     <Input
    //       placeholder="Enter Quotedrate"
    //       value={record.quotedrate}
    //       onChange={(e) =>
    //         handleInputChange(record.itemid, "quotedrate", e.target.value)
    //       }
    //       className="border rounded px-2 py-1"
    //     />
    //   ),
    // },
    {
      title: "Final Rate",
      dataIndex: "finalrate",
      key: "finalrate",
      render: (text, record) => (
        <Input
          placeholder="Enter Final Rate"
          value={record.finalrate}
          onChange={(e) =>
            handleInputChange(record.itemid, "finalrate", e.target.value)
          }
          className="border rounded px-2 py-1"
        />
      ),
    },
    {
      title: "Valid Days",
      dataIndex: "validdays",
      key: "validdays",
      render: (text, record) => (
        <Input
          placeholder="Enter Valid Days"
          value={record.validdays}
          onChange={(e) =>
            handleInputChange(record.itemid, "validdays", e.target.value)
          }
          className="border rounded px-2 py-1"
        />
      ),
    },
    // {
    //   title: "Negotiateable",
    //   dataIndex: "negotiateable",
    // },
  ];
  const onFinish = () => {
    console.log("val", selectedRows);
    const updateData = selectedRows.map((row) => ({
      quotationsrno: rowData?.quotationsrno,
      itemid: row?.itemid,
      itemname: row?.item,
      quantity: row?.quantity,
      quotedrate: row?.price,
      finalrate: row?.finalrate,
      validdays: row?.validdays,
      negotiateable: "Y",
    }));
    createQuotationdetail({
      data: updateData,
      message: "B",
    });
  };
  return (
    <section>
      <div>
        {/* <Form
          form={form}
          id="adddetailquotation"
          initialValues={{
            quotationsrno: rowData?.quotationsrno,
          }}
          onFinish={onFinish}
          layout="vertical"
        >
          <div className=" grid sm:grid-cols-2 grid-cols-1 gap-1">
            <Form.Item name="quotationsrno" label="Quotationsrno">
              <Input disabled />
            </Form.Item>
            {/* <Form.Item name="itemid" label="Item Name">
              <Select
                placeholder="Select Item Name"
                options={lovItemList?.data?.map((item) => ({
                  value: item?.itemid,
                  label: `${item?.item}`,
                }))}
                onChange={(value) => {
                  const updatedTableData = lovItemList?.data.find(
                    (item) => item.itemid === value
                  );
                  setTableData((prevTableData) => [
                    ...prevTableData,
                    ...[updatedTableData],
                  ]);
                }}
                showSearch
                filterOption={(input, option) =>
                  option?.label?.toLowerCase()?.includes(input?.toLowerCase())
                }
              />
            </Form.Item> 
            <Form.Item label=" ">
              <Button htmlType="submit" type="primary">
                Save New Record
              </Button>
            </Form.Item>
          </div>
        </Form> */}
      </div>
      <div>
        <Table
          columns={columns}
          dataSource={tableData}
          size="small"
          scroll={{ x: "fit-content" }}
          rowKey="itemid"
          rowSelection={rowSelection}
        />
      </div>
      <div>
        <Button onClick={onFinish} type="primary">
          Save New Record
        </Button>
      </div>
    </section>
  );
};
