import BusinessFunction from '../features/security/pages/BusinessFunction'
import ManageUsers from '../features/security/pages/ManageUsers'
import ProjectEqptList from '../features/security/pages/ProjectEqptList'
import Roles from '../features/security/pages/Roles'
import TransferUser from '../features/security/pages/TransferUser'

export const securityRoutes = [
  {
    path: 'business-module',
    element: <BusinessFunction />,
  },
  {
    path: 'roles',
    element: <Roles />,
  },
  {
    path: 'transferuser',
    element: <TransferUser />,
  },
  {
    path: 'users',
    element: <ManageUsers />,
  },
  {
    path: 'projectlist',
    element: <ProjectEqptList />,
  },
]
