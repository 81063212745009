import React, { useEffect, useRef, useState } from "react";
import {
  useGetPosDetailRecordMutation,
  useGetposmasterMutation,
  useLovCustomerInvoiceListMutation,
  useLovCustomerQuery,
  useReturnPOSMutation,
} from "../inventorypurchaseService";
import { Button, Form, Input, Modal, Select, Table } from "antd";
import openNotification from "../../../utils/notification";
import useTableFilters from "../../../hooks/useTableFilter";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import useFetchPDF from "../../../hooks/useFetchPDF";
export const PosReturn = () => {
  const [form] = Form.useForm();
  const filter = useTableFilters();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [showPdfModal, setShowPdfModal] = useState();
  const [reportData, getReport] = useFetchPDF();
  const itemSelectRefcustomer = useRef();
  const itemSelectRef = useRef();
  const itemSelectRefqty = useRef();
  const [isItemSelectOpencustomer, setIsItemSelectOpencustomer] =
    useState(false);
  const [isItemSelectOpen, setIsItemSelectOpen] = useState(false);
  const [posreturn, { data: getposrturn }] = useGetposmasterMutation();
  const { data: lovCustomer } = useLovCustomerQuery();
  const [
    savedetailpos,
    { data: getposdetailsale, isError: errorposdetailsale },
  ] = useGetPosDetailRecordMutation();
  const [savePosrteurn, { data: posreturnsavedata, isSuccess: saveSuccess }] =
    useReturnPOSMutation();
  const [savecustomer, { data: getinv }] = useLovCustomerInvoiceListMutation();
  useEffect(() => {
    if (getposrturn) {
      savedetailpos({
        transactionno: getposrturn?.data[0]?.transactionid,
        salnum: getposrturn?.data[0]?.salnum,
        message: "B",
      });
      form.setFieldsValue({ customername: getposrturn?.data[0]?.customerid });
      savecustomer({
        customerid: getposrturn?.data[0]?.customerid,
        message: "B",
      });
    }
  }, [getposrturn, form]); //eslint-disable-line
  useEffect(() => {
    if (saveSuccess) {
      getReport(`pos/GetPOSReturnRPT/${getposrturn?.data[0]?.invnum}`);
      setShowPdfModal(true);
      setTableData([]);
      form.resetFields();
    }
  }, [saveSuccess]); //eslint-disable-line
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.altKey && (e.key === "c" || e.key === "C")) {
        //|| e.key === "Enter"
        e.preventDefault();
        itemSelectRefcustomer.current.focus();
        setIsItemSelectOpencustomer(true);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.altKey && (e.key === "l" || e.key === "L")) {
        //|| e.key === "Enter"
        e.preventDefault();
        itemSelectRef.current.focus();
        setIsItemSelectOpen(true);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  const handleInputChange = (key, dataIndex, value) => {
    // Update the data array with the new value
    const newData = tableData.map((item) => {
      if (item.itemid === key) {
        // Ensure value is a number or null
        const parsedValue = value === "" ? null : parseInt(value, 10);
        return {
          ...item,
          [dataIndex]: parsedValue,
        };
      }
      return item;
    });

    setTableData(newData);
  };
  useEffect(() => {
    const updatedTableData = tableData.map((item) => ({
      ...item,
      total: item.price * (item.qty || 1),
    }));
    const totalValue = updatedTableData.reduce(
      (acc, item) => acc + (item.total || 0),
      0
    );
    form.setFieldsValue({ totalamount: totalValue }); //, finalamount: totalValue
    setTableData(updatedTableData);
  }, [tableData, form]);
  const columns = [
    {
      title: "Itemname",
      dataIndex: "itemname",
      ...filter("itemname"),
      sorter: (a, b) => a?.itemname?.localeCompare(b?.itemname),
    },
    {
      title: "Description",
      dataIndex: "item_name",
      ...filter("item_name"),
      sorter: (a, b) => a?.item_name?.localeCompare(b?.item_name),
    },

    {
      title: "Qty",
      dataIndex: "qty",
      render: (text, record) => (
        <Input
          ref={itemSelectRefqty}
          placeholder="Enter Qty"
          defaultValue={1}
          value={record.qty}
          onChange={(e) => {
            handleInputChange(record.itemid, "qty", e.target.value);
          }}
          // className=""
        />
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
    },
  ];
  const onFinish = (values) => {
    posreturn({
      invnum: values?.invnum,
    });
  };
  const onFinishFinal = () => {
    if (tableData?.length > 0) {
      const updateData = tableData.map((row) => ({
        itemid: row.itemid,
        itemname: row.itemname,
        quantity: row.qty || 1,
        price: row.price,
        totalprice: row.price * (row.qty || 1),
      }));
      const adjamt = form.getFieldValue("adjustedcamt") || 0;
      const total = form.getFieldValue("totalamount") || 1;
      savePosrteurn({
        totalinvamt: total,
        adjustedcamt: adjamt,
        returnedamnt: parseInt(total) + parseInt(adjamt),
        invnum: getposrturn?.data[0]?.invnum,
        customerid: getposrturn?.data[0]?.customerid,
        data: updateData,
      });
    } else openNotification("error", "Please Select Items", 10);
  };
  return (
    <section>
      <div className=" grid justify-end items-end mb-1">
        <Button
          type="primary"
          onClick={() => {
            navigate("/pointofsale");
          }}
        >
          Point of Sale
        </Button>
      </div>
      <h1 className=" text-center font-semibold text-base sm:text-lg p-2 bg-[#00b29c] text-white">
        Sale Return
      </h1>
      <div>
        <Form form={form} onFinish={onFinish} layout="vertical">
          <div className=" grid sm:grid-cols-4 grid-cols-1 gap-1">
            <Form.Item
              name="invnum"
              label="Invoice Number"
              className=" mb-0 sm:col-span-3"
            >
              <Input placeholder="Please Enter Invoice Number" />
            </Form.Item>
            <Form.Item label=" ">
              <Button type="primary" htmlType="submit">
                Get POS Return
              </Button>
            </Form.Item>
            <Form.Item name="customername" label="Customer" className=" mb-0 ">
              <Select
                ref={itemSelectRefcustomer}
                open={isItemSelectOpencustomer}
                onDropdownVisibleChange={(open) =>
                  setIsItemSelectOpencustomer(open)
                }
                placeholder="Select Customer Name"
                options={lovCustomer?.data?.map((item) => ({
                  value: item?.customerid,
                  label: `${item?.name}`,
                }))}
                onChange={(value) => {
                  if (value) {
                    savecustomer({ customerid: value });
                  }
                }}
                showSearch
                filterOption={(input, option) =>
                  option?.label?.toLowerCase()?.includes(input?.toLowerCase())
                }
                allowClear
              />
            </Form.Item>
            <Form.Item
              name="customerinvnum"
              label="Customer Invoices"
              className=" mb-0 sm:col-span-3"
            >
              <Select
                placeholder="Select Customer NInv Number"
                options={getinv?.data?.map((item) => ({
                  value: item?.invnum,
                  label: (
                    <span className=" grid sm:grid-cols-2 grid-cols-1 justify-center items-center">
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Inv Number&nbsp;:&nbsp;{item.invnum}
                      </span>
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Date&nbsp;:&nbsp;
                        {moment(item.invdate).format("DD-MMM-YYYY HH:mm:ss")}
                      </span>
                    </span>
                  ),
                }))}
                onChange={(value) => {
                  if (value) {
                    posreturn({
                      invnum: value,
                    });
                  }
                }}
                showSearch
                filterOption={(input, option) =>
                  option?.value?.toLowerCase()?.includes(input?.toLowerCase())
                }
                allowClear
              />
            </Form.Item>
            <Form.Item
              name="itemid"
              label="Item Name"
              className=" sm:col-span-3"
            >
              <Select
                ref={itemSelectRef}
                open={isItemSelectOpen}
                onDropdownVisibleChange={(open) => setIsItemSelectOpen(open)}
                placeholder="Select Item Name"
                disabled={!getposdetailsale || errorposdetailsale}
                options={getposdetailsale?.data?.map((item) => ({
                  searchvalue: item.item_name,
                  value: item?.itemid,
                  label: (
                    <span className=" grid sm:grid-cols-3 justify-center items-center">
                      {item?.item_name}
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Qauntity: {item.quantity}
                      </span>
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Price: {item.price}
                      </span>
                    </span>
                  ),
                }))}
                allowClear
                onChange={(value) => {
                  setIsItemSelectOpen(false); // Close the dropdown after selecting
                  const updatedTableData = getposdetailsale?.data.find(
                    (item) => item.itemid === value
                  );
                  if (updatedTableData) {
                    const duplicate = tableData?.find(
                      (item) => item.itemid === updatedTableData?.itemid
                    );
                    if (!duplicate) {
                      setTableData((prevTableData) => [
                        ...prevTableData,
                        ...[updatedTableData],
                      ]);
                      setTimeout(() => {
                        if (itemSelectRefqty.current) {
                          itemSelectRefqty.current.focus();
                        }
                      }, 100);
                    } else
                      openNotification(
                        "error",
                        "Item is Already in The Table",
                        10
                      );
                  }
                }}
                showSearch
                filterOption={(input, option) =>
                  option?.searchvalue
                    ?.toLowerCase()
                    ?.includes(input?.toLowerCase())
                }
              />
            </Form.Item>
            <Form.Item className=" sm:col-span-3">
              <Table
                size="small"
                columns={columns}
                dataSource={tableData}
                scroll={{ x: "fit-content" }}
              />
            </Form.Item>
            <div className="grid sm:grid-cols-2 gap-1">
              <Form.Item
                name="totalamount"
                label="Total Amount"
                className=" mb-0"
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                name="adjustedcamt"
                label="Adjusted Amount"
                className=" mb-0"
              >
                <Input />
              </Form.Item>
              <Form.Item label=" ">
                <Button type="primary" onClick={onFinishFinal}>
                  Save Sale Return
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
        <Modal
          title="Sale Return Report"
          open={showPdfModal}
          onCancel={() => setShowPdfModal(false)}
          centered
          width={1000}
          destroyOnClose
          footer={[
            <Button key="1" onClick={() => setShowPdfModal(false)}>
              Cancel
            </Button>,
          ]}
        >
          <iframe
            width="100%"
            height="500px"
            src={reportData}
            title="Finance Report"
          ></iframe>
        </Modal>
      </div>
    </section>
  );
};
