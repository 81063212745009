import { api } from "../api/api";

export const purchaseOrderApi = api.injectEndpoints({
  endpoints: (build) => ({
    lovgetvendorlist: build.query({
      query: (body) => `materialmanagement/getvendorlist`,
    }),

    getNomncltreLov: build.query({
      query: (body) => `materialmanagement/getnomncltrelov/${body.unit}`,
    }),

    getrequestusers: build.query({
      query: (body) => `materialmanagement/getrequestusers`,
    }),

    getreqtype: build.query({
      query: (body) => `materialmanagement/getreqtype`,
    }),

    getrequnit: build.query({
      query: (body) => `materialmanagement/getrequnit`,
    }),

    getreqstatus: build.query({
      query: (body) => `materialmanagement/getreqstatus`,
    }),

    getreqmasterquery: build.query({
      query: (body) => `materialmanagement/getreqmasterquery`,
    }),

    lovgetuom: build.query({
      query: (body) => `materialmanagement/getuom`,
    }),

    getunitlov: build.query({
      query: (body) => `materialmanagement/getunitlov/${body}`,
    }),

    lovgetporeqtype: build.query({
      query: (body) => `materialmanagement/getporeqtype`,
    }),
    lovgetposession: build.query({
      query: (body) => `materialmanagement/getposession`,
    }),

    lovgetponomnlist: build.mutation({
      query: (body) =>
        `materialmanagement/getponomnlist/${body.unit}/${body.vendorid}`,
    }),

    getporeceiving: build.mutation({
      query: (body) => `materialmanagement/getporeceiving/${body.ponum}`,
    }),

    getpolist: build.query({
      query: (body) => `materialmanagement/getpolistdto`,
    }),

    getdemandlist: build.query({
      query: (body) => `materialmanagement/getdemandlist`,
    }),

    getPoDetailListDto: build.mutation({
      query: (body) =>
        `materialmanagement/getpolistdetaildto/${body.ponum}/${body.poseq}`,
    }),

    getrequestdetaildto: build.mutation({
      query: (body) =>
        `materialmanagement/getrequestdetaildto/${body.reqdate}/${body.reqnum}`,
    }),

    getponomnlistdetail: build.mutation({
      query: (body) =>
        `materialmanagement/getponomnlistdetail/${body.unit}/${body.vendorid}/${body.nomncltre}`,
    }),

    getposchedule: build.mutation({
      query: (body) =>
        `materialmanagement/getposchedule/${body.podate}/${body.poseq}/${body.ponum}`,
    }),

    getdemanddetail: build.mutation({
      query: (body) => `materialmanagement/getdemanddetail/${body.demandid}`,
    }),

    savepo: build.mutation({
      query: (body) => ({
        url: "materialmanagement/savepo",
        method: "POST",
        body,
      }),
    }),

    SavePoDetail: build.mutation({
      query: (body) => ({
        url: "materialmanagement/savepodetail",
        method: "POST",
        body,
      }),
    }),

    savedemanddetail: build.mutation({
      query: (body) => ({
        url: "materialmanagement/savedemanddetail",
        method: "POST",
        body,
      }),
    }),

    poordered: build.mutation({
      query: (body) => ({
        url: "materialmanagement/poordered",
        method: "POST",
        body,
      }),
    }),
    itemreceive: build.mutation({
      query: (body) => ({
        url: "materialmanagement/itemreceive",
        method: "POST",
        body,
      }),
    }),

    poreceiveweb: build.mutation({
      query: (body) => ({
        url: "materialmanagement/poreceiveweb",
        method: "POST",
        body,
      }),
    }),
    getPoNomnList: build.query({
      query: (body) =>
        `materialmanagement/getponomnlist/${body.unit}/${body.vendorid}`,
    }),

    savedemand: build.mutation({
      query: (body) => ({
        url: "materialmanagement/savedemand",
        method: "POST",
        body,
      }),
    }),

    getreqmaster: build.query({
      query: (body) => `materialmanagement/getreqmaster`,
    }),

    saveschd: build.mutation({
      query: (body) => ({
        url: "materialmanagement/saveschd",
        method: "POST",
        body,
      }),
    }),
    getmaterialloc: build.mutation({
      query: (body) =>
        `materialmanagement/getmaterialloc/${body.co}/${body.unit}`,
    }),

    getonhanddetail: build.mutation({
      query: (body) =>
        `materialmanagement/getonhanddetail/${body.unit}/${body.nomncltre}`,
    }),
  }),
});

export const {
  useGetonhanddetailMutation,
  useLovgetvendorlistQuery,
  useGetunitlovQuery,
  useLovgetuomQuery,
  useLovgetporeqtypeQuery,
  useLovgetposessionQuery,
  useLovgetponomnlistMutation,
  useSavepoMutation,
  useSaveschdMutation,
  useGetpolistQuery,
  useGetPoDetailListDtoMutation,
  usePoorderedMutation,
  useGetPoNomnListQuery,
  useGetponomnlistdetailMutation,
  useSavePoDetailMutation,
  useGetporeceivingMutation,
  usePoreceivewebMutation,
  useGetposcheduleMutation,
  useGetreqmasterQuery,
  useGetrequestdetaildtoMutation,
  useItemreceiveMutation,
  useGetmateriallocMutation,

  useGetrequestusersQuery,
  useGetreqtypeQuery,
  useGetrequnitQuery,
  useGetreqstatusQuery,
  useGetreqmasterqueryQuery,
  useGetdemandlistQuery,
  useSavedemandMutation,
  useGetdemanddetailMutation,
  useSavedemanddetailMutation,
  useGetNomncltreLovQuery,
} = purchaseOrderApi;
