import React, { useEffect, useState } from "react";
import {
  useCreateQuotationMasterMutation,
  useCreateQuotationnewrecordMutation,
  useGetQuotationReportMutation,
  useGetQutationdetailMutation,
  useGetQutationinfoQuery,
  useLovCustomerQuery,
  useLovItemListQuery,
  useUpdateQuotationnewrecordMutation,
} from "../inventorypurchaseService";
import useTableFilters from "../../../hooks/useTableFilter";
import { Button, Modal, Table, Tag, Tooltip } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import AddQuotation from "../components/AddQuotation";
import moment from "moment";
import { AddDetailQuotation } from "../components/AddDetailQuotation";
import useFetchPDF from "../../../hooks/useFetchPDF";
import { FaRegFilePdf } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
const Quotation = () => {
  const navigate = useNavigate();
  const {
    data: getmasterqoutation,
    isLoading,
    refetch,
  } = useGetQutationinfoQuery();
  const { data: lovItemList } = useLovItemListQuery();
  const { data: lovCustomer } = useLovCustomerQuery();
  const [
    createQuotationMaster,
    { isSuccess: addSuccess, isLoading: addLoading },
  ] = useCreateQuotationMasterMutation();
  const [
    createQuotationdetail,
    {
      data: getdetailData,
      isSuccess: adddetailSuccess,
      isLoading: adddetailLoading,
    },
  ] = useCreateQuotationnewrecordMutation();
  const [
    detaildata,
    { data: getdetaildata, isError: getError, isLoading: detailLoading },
  ] = useGetQutationdetailMutation();
  const [deletemaster, { data: getdeletedata, isSuccess: deleteSuccess }] =
    useUpdateQuotationnewrecordMutation();
  const [showAddModaldetail, setShowAddModaldetail] = useState(false);
  const [rowData, setRowData] = useState(false);
  const filter = useTableFilters();
  const [tableDatainfo, setTableDatainfo] = useState([]);
  const [tableDataDetail, setTableDataDetail] = useState([]);
  const [totalactual, setTotalactual] = useState(null);
  const [totalfinal, setTotalfinal] = useState(null);
  const [showPdfModal, setShowPdfModal] = useState();
  const [showPdfModalpath, setShowPdfModalpath] = useState(false);
  const [reportData, getReport] = useFetchPDF();
  const [savereport, { data: getreportdata }] = useGetQuotationReportMutation();
  useEffect(() => {
    if (getreportdata) {
      setShowPdfModalpath(true);
    }
  }, [getreportdata]);
  useEffect(() => {
    if (getmasterqoutation) {
      setTableDatainfo(getmasterqoutation?.data);
    }
  }, [getmasterqoutation]);
  useEffect(() => {
    if (getError) {
      setTableDataDetail([]);
    }
  }, [getError]);
  useEffect(() => {
    if (getdetaildata) {
      setTableDataDetail(getdetaildata?.data);
      const totalactualprice = getdetaildata?.data.reduce(
        (a, v) => (a = a + v.actualprice),
        0
      );
      const totalfinalprice = getdetaildata?.data.reduce(
        (a, v) => (a = a + v.finalprice),
        0
      );
      setTotalactual(totalactualprice);
      setTotalfinal(totalfinalprice);
    }
  }, [getdetaildata]);
  useEffect(() => {
    if (addSuccess)
      //  setShowAddModaldetail(false);
      handlerefetch();
  }, [addSuccess]); // eslint-disable-line
  useEffect(() => {
    if (adddetailSuccess) {
      setShowAddModaldetail(false);
      detaildata({
        quotationno: getdetailData?.data.quotationsrno,
      });
    }
  }, [adddetailSuccess]); //eslint-disable-line
  useEffect(() => {
    if (deleteSuccess) {
      handlerefetch();

      detaildata({
        quotationno: getdeletedata?.data.quotationsrno,
      });
    }
  }, [deleteSuccess]); // eslint-disable-line
  const ReportButton = (data) => {
    getReport(`pos/GetQuotationReport/${data.quotationsrno}`);
    setShowPdfModal(true);
  };

  const tableColumns = [
    {
      title: "Description",
      dataIndex: "quotationdescr",
      ...filter("quotationdescr"),
    },
    {
      title: "Customer Name",
      dataIndex: "customername",
      ...filter("customername"),
    },
    {
      title: "Customer Mob#",
      dataIndex: "customercellno",
      ...filter("customercellno"),
    },
    {
      title: "Customer Mob#",
      dataIndex: "customercellno",
      ...filter("customercellno"),
    },
    {
      title: "Quotation Date",
      dataIndex: "quotationdate",
      render: (data) => (
        <div>{moment(data?.quotationdate)?.format("YYYY-MM-DD")}</div>
      ),
    }, //
    {
      title: "Quotationstatus",
      render: (_, { quotationstatus }) =>
        quotationstatus === "Y" ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">In Active</Tag>
        ),
      sorter: (a, b) => a?.quotationstatus?.localeCompare(b?.quotationstatus),
    },
    {
      title: "Action(s)",
      render: (_, record) => (
        <div className=" flex justify-center items-center gap-1">
          <Tooltip title="Add Quotation">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setRowData(record);
                setShowAddModaldetail(true);
              }}
            />
          </Tooltip>
          <Tooltip title="Quotation Report">
            <Button
              type="primary"
              className="flex justify-center items-center"
              icon={<FaRegFilePdf />}
              onClick={() => {
                ReportButton(record);
                savereport({ quotationno: record.quotationsrno });
              }}
            />
          </Tooltip>
          {/* <Tooltip title="WhatsApp">
            <Button
              type="primary"
              className="flex justify-center items-center"
              icon={<FaWhatsappSquare />}
              onClick={() => {
                window.open(
                  "https://wa.me/923045665432?text=I'm%20interested%20in%20your%20car%20for%20sale",
                  "_blank"
                );
              }}
            />
          </Tooltip> */}
        </div>
      ),
    },
  ];
  const columnDetail = [
    // {
    //   title: "Seq Number",
    //   dataIndex: "seqnum",
    // },
    {
      title: "Quotation Sr No",
      dataIndex: "quotationsrno",
    },
    {
      title: "Description",
      dataIndex: "item_name",
    },
    {
      title: "Item Name",
      dataIndex: "itemname",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
    },
    {
      title: "Quoted Rate",
      dataIndex: "quotedrate",
    },
    {
      title: "Actual Price",
      dataIndex: "actualprice",
      render: (_, record) => {
        const quantity = record.quantity || 0;
        const quotedRate = record.quotedrate || 0;
        const total = quantity * quotedRate;

        return <div>{total}</div>;
      },
    },
    {
      title: "Final Rate",
      dataIndex: "finalrate",
    },
    {
      title: "Final Price",
      dataIndex: "finalprice",
      render: (_, record) => {
        const quantity = record.quantity || 0;
        const finalrate = record.finalrate || 0;
        const total = quantity * finalrate;
        return <div>{total}</div>;
      },
    },
    {
      title: "Valid Days",
      dataIndex: "validdays",
    },
    // {
    //   title: "Negotiateable",
    //   dataIndex: "negotiateable",
    // },
    {
      title: "Action(s)",
      render: (_, record) => (
        <Tooltip title="Update Inventory Stock">
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => {
              deletemaster({
                data: [
                  {
                    quotationsrno: record?.quotationsrno,
                    seqnum: record?.seqnum,
                  },
                ],
              });
            }}
          />
        </Tooltip>
      ),
    },
  ];
  const handlerefetch = () => {
    refetch();
  };
  return (
    <section>
      <div className=" grid justify-end items-end mr-[1.5%]">
        <Button
          type="primary"
          onClick={() => {
            navigate("/pointofsale");
          }}
        >
          Point of Sale
        </Button>
      </div>
      <div className=" px-5 py-3 ">
        <h1 className=" text-center font-semibold text-base sm:text-lg p-2 bg-[#00b29c] text-white">
          Quotation
        </h1>
        {/* <Tooltip title="Add New Stock ">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setShowAddModaldetail(true)}
          />
        </Tooltip> */}
        <fieldset className="p-2 mt-2">
          <legend className=" text-[#00b29c]">Add Quotation</legend>
          <AddQuotation
            createQuotationMaster={createQuotationMaster}
            handlerefetch={handlerefetch}
            lovItemList={lovItemList}
            lovCustomer={lovCustomer}
          />
        </fieldset>
      </div>
      <div className="p-2">
        <Table
          columns={tableColumns}
          dataSource={tableDatainfo}
          pagination={{ defaultPageSize: 5 }}
          loading={isLoading || addLoading}
          bordered
          size="small"
          scroll={{ x: "fit-content" }}
          rowKey="quotationsrno"
          onRow={(record) => ({
            onClick: () => {
              detaildata({
                quotationno: record?.quotationsrno,
              });
            },
          })}
        />
      </div>
      <div className="p-2">
        <Table
          columns={columnDetail}
          dataSource={tableDataDetail}
          pagination={{ defaultPageSize: 10 }}
          loading={detailLoading || adddetailLoading}
          bordered
          size="small"
          scroll={{ x: "fit-content" }}
          rowKey="seqnum"
        />
        {/* <div className="flex flex-col gap-1 justify-end items-end mr-3">
          <div className="flex text-[#00b29c]">
            Total Actual Price&nbsp;=&nbsp;
            <h1 className=" text-md font-semibold text-[#00b29c]">
              {totalactual}
            </h1>
          </div>
          <div className="flex text-[#00b29c]">
            Total Final Price&nbsp;=&nbsp;
            <h1 className=" text-md font-semibold text-[#00b29c]">
              {totalfinal}
            </h1>
          </div>
          <div className="flex text-[#00b29c]">
            Discount&nbsp;=&nbsp;
            <h1 className=" text-md font-semibold text-[#00b29c]">{10}</h1>
          </div>
        </div> */}
      </div>

      <Modal
        className="hideFooter"
        title="Add Quotation Detail"
        open={showAddModaldetail}
        onCancel={() => setShowAddModaldetail(false)}
        centered
        width={1000}
        destroyOnClose
        // footer={[
        //   <Button
        //     key="1"
        //     htmlType="submit"
        //     form="adddetailquotation"
        //     loading={addLoading}
        //     type="primary"
        //   >
        //     Add
        //   </Button>,
        // ]}
      >
        <AddDetailQuotation
          createQuotationdetail={createQuotationdetail}
          rowData={rowData}
          tableDataDetail={tableDataDetail}
        />
      </Modal>
      <Modal
        title="Print Reciept"
        open={showPdfModal}
        onCancel={() => setShowPdfModal(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button key="1" onClick={() => setShowPdfModal(false)}>
            Cancel
          </Button>,
        ]}
      >
        <iframe
          width="100%"
          height="500px"
          src={reportData}
          title="Finance Report"
        ></iframe>
      </Modal>
      <Modal
        title="Quotation Report Path"
        open={showPdfModalpath}
        onCancel={() => setShowPdfModalpath(false)}
        centered
        width={1000}
        destroyOnClose
        footer={[
          <Button key="1" onClick={() => setShowPdfModalpath(false)}>
            Cancel
          </Button>,
        ]}
      >
        <div>
          <h1>Quotation Report Link : {getreportdata?.reportPath}</h1>
        </div>
      </Modal>
    </section>
  );
};

export default Quotation;
