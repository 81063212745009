import { Button, Form, Input, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import formValidationMessages from "../../../utils/formValidationMessages";
import useTableFilters from "../../../hooks/useTableFilter";

const AddQuotation = ({ createQuotationMaster, lovItemList, lovCustomer }) => {
  const [form] = Form.useForm();
  const [tableData, setTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const filter = useTableFilters();
  // Handle when the user selects a Purchase Order
  useEffect(() => {
    if (lovItemList) {
      const updatedTableData = lovItemList?.data.map((item) => ({
        code: item.code,
        descr: item.descr,
        item_name: item.item_name,
        price: item.price,
        negotiateable: "N",
        validdays: 7,
      }));
      setTableData(updatedTableData);
    }
  }, [lovItemList]);
  const handleInputChange = (key, dataIndex, value) => {
    // Update the data array with the new value
    const newData = [...tableData];
    const index = newData.findIndex((item) => key === item.code);
    if (index > -1) {
      newData[index][dataIndex] = value;
      setTableData(newData);
    }
  };
  const columns = [
    {
      title: "Item ID",
      dataIndex: "code",
    },
    {
      title: "Item Name",
      dataIndex: "descr",
      key: "descr",
      ...filter("descr"),
      sorter: (a, b) => a?.descr?.localeCompare(b?.descr),
    },
    {
      title: "Item Name",
      dataIndex: "item_name",
      key: "item_name",
      ...filter("item_name"),
      sorter: (a, b) => a?.item_name?.localeCompare(b?.item_name),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text, record) => (
        <Input
          placeholder="Enter Quantity"
          defaultValue={1}
          value={record.quantity}
          onChange={(e) =>
            handleInputChange(record.code, "quantity", e.target.value)
          }
          className="border rounded px-2 py-1"
        />
      ),
    },
    // {
    //   title: "Quotedrate",
    //   dataIndex: "quotedrate",
    //   key: "quotedrate",
    //   render: (text, record) => (
    //     <Input
    //       placeholder="Enter Quotedrate"
    //       value={record.quotedrate}
    //       onChange={(e) =>
    //         handleInputChange(record.code, "quotedrate", e.target.value)
    //       }
    //       className="border rounded px-2 py-1"
    //     />
    //   ),
    // },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Final Rate",
      dataIndex: "finalrate",
      key: "finalrate",
      render: (text, record) => (
        <Input
          placeholder="Enter Final Rate"
          value={record.finalrate}
          onChange={(e) =>
            handleInputChange(record.code, "finalrate", e.target.value)
          }
          className="border rounded px-2 py-1"
        />
      ),
    },
    {
      title: "Valid Days",
      dataIndex: "validdays",
      key: "validdays",
      render: (text, record) => (
        <Input
          placeholder="Enter Valid Days"
          value={record.validdays}
          onChange={(e) =>
            handleInputChange(record.code, "validdays", e.target.value)
          }
          className="border rounded px-2 py-1"
        />
      ),
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
  };
  const onFinish = (values) => {
    const updateData = selectedRows.map((row) => ({
      itemid: row?.code,
      itemname: row?.descr,
      quantity: row?.quantity || 1,
      quotedrate: row?.price,
      finalrate: row?.finalrate,
      validdays: row?.validdays,
      negotiateable: row?.negotiateable,
    }));
    createQuotationMaster({
      ...values,
      quotationdescr: ".",
      quotationtype: "C",
      data: updateData,
      message: "B",
    });
  };
  return (
    <div>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        id="addQuotationForm"
        validateMessages={formValidationMessages}
        className="grid grid-cols-3 gap-2"
      >
        {/* <Form.Item name="quotationdescr" label="Quotation description">
          <Input />
        </Form.Item>
        <Form.Item name="quotationtype" label="Quotation Type">
          <Select
            placeholder="Select  Quotation Type"
            options={[
              { label: "Standard", value: "Standard" },
              { label: "Local", value: "Local" },
            ]}
          />
        </Form.Item> */}
        <Form.Item label="Customer Name" className="mb-0">
          <Select
            placeholder="Select Customer Name"
            onChange={(value) => {
              const selectedOption = lovCustomer?.data?.find(
                (item) => item?.customerid === value
              );
              form.setFieldsValue({
                customername: selectedOption?.name,
                customeraddress: selectedOption.address,
                customercellno: selectedOption.cellno,
              });
            }}
            options={lovCustomer?.data?.map((item) => ({
              value: item?.customerid,
              label: `${item?.name}`,
            }))}
            showSearch
            filterOption={(input, option) =>
              option?.label?.toLowerCase()?.includes(input?.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item name="customername" className=" hidden">
          <Input />
        </Form.Item>
        <Form.Item
          name="customeraddress"
          label="Customer Address"
          className="mb-0"
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="customercellno"
          label="Nustomer Mob Number"
          className="mb-0"
        >
          <Input disabled />
        </Form.Item>
        <Form.Item label=" " className=" flex justify-end items-end col-span-3">
          <Button htmlType="submit" type="primary">
            Save
          </Button>
        </Form.Item>
      </Form>
      <Table
        size="small"
        columns={columns}
        dataSource={tableData}
        rowKey="code"
        scroll={{ x: "fit-content" }}
        rowSelection={rowSelection}
      />
    </div>
  );
};

export default AddQuotation;
